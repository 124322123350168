import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import AnalyticsLeftColumn from "../../components/AnalyticsLeftColumn";
import AnalyticsRightColumn from "../../components/AnalyticsRightColumn";

// 1. define Hreflang data, for href tags and language button
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/analytics"
  },
  {
    hreflang: "de",
    href: "/de/analytics"
  },
  {
    hreflang: "da",
    href: "/da/analytics"
  },
  {
    hreflang: "se",
    href: "/se/analys"
  },
  {
    hreflang: "no",
    href: "/no/analytics"
  },
  {
    hreflang: "nl",
    href: "/nl/analytics"
  },
  {
    hreflang: "x-default",
    href: "/en/analytics"
  }
];

const Analytics = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        {/* 2. define SEO meta data */}
        <SEO
          title="Tutorials über Webanalyse, GA und Google Tag Manager"
          lang="de"
          description="Lernen Sie die Grundlagen und fortgeschrittenen Konzepte von Web Analytics, Google Analytics & GTM mit Schritt-für-Schritt-Tutorials. Steigern Sie Ihre Marketingleistung mit umsetzbaren Erkenntnissen!"
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
        // canonical="/en"
        />
        <MainFrontpage>
          <AnalyticsLeftColumn />
          <AnalyticsRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Analytics);

